"use client";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import Mail from "../Icons/Mail";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Form,
  FormMessage,
} from "../ui/form";
import { NewsletterSchema, subscribe } from "@/lib/newsletter";

export interface Props {
  mobile?: boolean;
}

type NewsletterFormData = z.infer<typeof NewsletterSchema>;

const NewsletterSignup: React.FC<Props> = ({ mobile }) => {
  const form = useForm<NewsletterFormData>({
    resolver: zodResolver(NewsletterSchema),
    defaultValues: {
      email: "",
      privacy: false,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = form;
  const checked = form.watch("privacy");

  const handleSubscribe = async (data: NewsletterFormData) => {
    try {
      const response = await subscribe(data.email);
      if (response.ok) {
        toast({
          variant: "success",
          title: "Iscrizione avvenuta con successo",
          description: "Grazie per esserti iscritto alla nostra newsletter!",
        });
        reset();
      } else {
        throw new Error("Qualcosa è andato storto");
      }
    } catch (error) {
      const err = error as Error;
      console.error(err);
      toast({
        variant: "destructive",
        title: "Errore di iscrizione",
        description:
          err.message || "Qualcosa è andato storto, riprovare più tardi.",
      });
    }
  };

  return (
    <div className={`flex flex-col justify-between ${mobile ? "gap-6" : ""}`}>
      <p
        className={`text-lg text-primary-white font-medium ${
          mobile ? "text-center" : ""
        }`}
      >
        Iscriviti alla nostra newsletter
      </p>
      <Form {...form}>
        <form
          className="flex flex-col space-y-4"
          onSubmit={handleSubmit(handleSubscribe)}
        >
          <div className="flex flex-row">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder="email@email.com"
                      {...field}
                      className="appearance-none py-2 pl-6 bg-secondary-800 border-none rounded-l-lg rounded-r-none opacity-100 text-secondary-400 text-base focus-visible:ring-offset-1"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="bg-blue-light hover:bg-blue-light text-primary-black rounded-r-lg rounded-l-none py-2 px-4 flex flex-row items-center gap-2 text-base font-semibold w-fit"
              disabled={isSubmitting || !checked}
            >
              <Mail size="20" />
              Iscriviti
            </Button>
          </div>

          <FormField
            control={form.control}
            name="privacy"
            render={({ field }) => (
              <FormItem className="flex items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    className="appearance-none size-4 rounded-sm border-secondary-400 bg-primary-black checked:bg-secondary-500 focus:checked:!bg-secondary-500 focus:ring-offset-0 focus:ring-0"
                  />
                </FormControl>
                <FormLabel className="text-xs text-secondary-400 w-4/5">
                  Accetto la Privacy Policy e Acconsento a ricevere
                  comunicazioni di marketing
                </FormLabel>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  );
};

export default NewsletterSignup;
