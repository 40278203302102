interface MailProps {
  size?: string;
}

const Mail = ({ size }: MailProps) => {
  return (
    <svg
      width={size || "18"}
      height={size || "13"}
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0.851562H17V11.982H1V0.851562Z"
        stroke="#111827"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5907 1.25586L9.00042 7.45969L1.41016 1.25586"
        stroke="#111827"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Mail;
