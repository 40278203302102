import { z } from "zod";

export const NewsletterSchema = z.object({
  email: z.string().email("Inserisci un'email valida"),
  privacy: z.literal<boolean>(true, {
    message: "Devi accettare la Privacy Policy per procedere.",
  }),
});

export async function subscribe(email: string) {
  const hutk = document.cookie
    .split("; ")
    .find((row) => row.startsWith("hubspotutk="))
    ?.split("=")[1];

  let formData = {
    submittedAt: Date.now(),
    fields: [
      {
        objectTypeId: "0-1",
        name: "email",
        value: email,
      },
    ],
    context: {
      hutk,
      pageUri: document.location.href,
      pageName: document.title,
    },
  };

  return await fetch(
    `https://api.hsforms.com/submissions/v3/integration/submit/${
      import.meta.env.PUBLIC_HS_PORTAL_ID
    }/${import.meta.env.PUBLIC_HS_FORM_ID_NEWSLETTER}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }
  );
}
